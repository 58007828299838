import { DownloadUrl, MutationRequest, PaginatedRequest } from 'app-types';

export enum GroupActiveStatus {
	Active = 'active',
	Inactive = 'deactivated'
}

export interface ModuleSettings {
	id: string;
	moduleEnabled: boolean;
	viewAllCourses: boolean;
	spotCheckNumber: string;
}
export interface Group {
	id: number;
	name: string;
	parentId: number | null;
	active: GroupActiveStatus;
	syncCode: string;
	isProperty: boolean;
	isArchived: boolean;
	level: number;
	moduleSettings: ModuleSettings | null;
	parents: GroupParent[];
	canBeProperty: boolean;
	deletedDate: string | null;
	autoactivate: boolean;
	sso: boolean;
}

export type GroupParent = Pick<
	Group,
	'id' | 'name' | 'moduleSettings' | 'isProperty'
>;

export interface GetGroupsListRequest {
	sort?: string;
	filters: Partial<
		Omit<Group, 'active' | 'isArchived' | 'isProperty'> & {
			depth: number;
			active: string[];
			isProperty: boolean[];
			isArchived: boolean[];
			withDeleted?: boolean;
		}
	>;
}

export interface GetGroupsListResponse {
	listGroups: Group[];
}

export type OwnershipGroup = Pick<Group, 'id' | 'name'> & {
	status: GroupActiveStatus;
	groups: {
		id: number;
		name: string;
	}[];
};

export interface GetOwnershipGroupsRequest {
	filters: Partial<Pick<OwnershipGroup, 'name'>>;
}

export interface GetOwnershipGroupsResponse {
	listOwnershipGroups: OwnershipGroup[];
}

export interface OwnershipGroupProperty {
	id: number;
	property: string;
	status: GroupActiveStatus;
	tenantName: string;
	isDeleted: boolean;
	isArchived: boolean;
}

export interface GetOwnershipGroupPropertiesRequest {
	id: number;
	sort: string;
	filters: Partial<
		Pick<OwnershipGroupProperty, 'property' | 'tenantName'> & {
			status: string[];
			isDeleted: boolean[];
			isArchived: boolean[];
		}
	>;
}

export interface GetOwnershipGroupPropertiesResponse {
	listOwnershipGroupProperties: OwnershipGroupProperty[];
}

export type DeletedGroup = Pick<
	Group,
	'id' | 'name' | 'isProperty' | 'syncCode' | 'isArchived' | 'active'
> & {
	tenantName?: string;
	hierarchy: string;
	daysBeforeRemoval: number | null;
	deletedDate: string;
};

export interface GetDeletedGroupsRequest {
	sort: string;
	search?: string;
	filters: Partial<
		Omit<DeletedGroup, 'active' | 'isProperty' | 'isArchived'> & {
			isProperty: boolean[];
			isArchived: boolean[];
			active: string[];
		}
	>;
}

export interface GetDeletedGroupsResponse {
	listDeletedGroups: DeletedGroup[];
}

export type GroupDetails = Group & {
	moduleSettings: {
		moduleEnabled: boolean;
		viewAllCourses: boolean;
		spotCheckNumber: number;
	} | null;
	parents: Group[];
	isTenant: boolean;
	tenantId: string;
	path: string;
	userPoolId: string;
	cognitoClientId: string;
	systemId: string;
};

export interface GetGroupRequest {
	id: number;
}
export interface GetGroupResponse {
	getGroup: GroupDetails;
}

interface CreateGroupInputBase {
	name: string;
	active: string;
	syncCode: string | null;
	isProperty: boolean;
	moduleEnabled: boolean;
	isArchived: boolean;
	autoactivate: boolean;
	sso: boolean;
}

type CreateTenantInput = CreateGroupInputBase & {
	viewAllCourses: boolean;
	tenantId: string;
	systemId: string;
};

type CreateGroupInput = CreateGroupInputBase & {
	parentId: number | null;
};

type EditTenantInput = Omit<CreateTenantInput, 'systemId'> & {
	id: number;
};

type EditGroupInput = CreateGroupInput & {
	id: number;
};

export type CreateTenantRequest = MutationRequest<CreateTenantInput>;
export type EditTenantRequest = MutationRequest<EditTenantInput>;
export type CreateGroupRequest = MutationRequest<CreateGroupInput>;
export type EditGroupRequest = MutationRequest<EditGroupInput>;

export type MoveGroupRequest = MutationRequest<{
	groupId: number;
	newParentGroupId: number;
}>;

export type RestoreGroupsRequest = MutationRequest<{ ids: string[] }>;

export type CreateOwnershipGroupRequest = MutationRequest<
	Pick<OwnershipGroup, 'name' | 'status'> & {
		groups: number[];
	}
>;

export interface CreateOwnershipGroupResponse {
	createOwnershipGroup: OwnershipGroup;
}

export type EditOwnershipGroupRequest = MutationRequest<
	Pick<OwnershipGroup, 'id' | 'name' | 'status'> & {
		groups: number[];
	}
>;
export type EditOwnershipGroupResponse = CreateOwnershipGroupResponse;

export interface GetOwnershipGroupRequest {
	id: number;
}

export interface GetOwnershipGroupResponse {
	getOwnershipGroupById: OwnershipGroup;
}

export interface Property {
	id: number;
	name: string;
	status: string;
	tenantId: number;
	isProperty: boolean;
	isTenant: boolean;
	isDeleted: boolean;
	isArchived: boolean;
	deletedDate: string | null;
	autoactivate: boolean;
	sso: boolean;
}

export interface GetPropertiesRequest {
	filters: {
		name?: string;
	};
}
export interface GetPropertiesResponse {
	listProperties: Property[];
}

export interface RemoveOwnershipGroupPropertiesRequest {
	id: number;
	groupIds: number[];
}

export interface DeleteOwnerShipGroupParams {
	id: number;
}

export type DeleteOwnerShipGroupRequest = MutationRequest<DeleteOwnerShipGroupParams>;

export type ResetGroupUsersPasswordsRequest = {
	id: number;
};

export type ResetGroupUsersPasswordsResponse = {
	resetGroupUsersPasswords: {
		totalCount: number;
	};
};

interface AddGroupInput
	extends Omit<
		CreateGroupInput,
		| 'moduleEnabled'
		| 'isArchived'
		| 'syncCode'
		| 'path'
		| 'userPoolId'
		| 'cognitoClientId'
		| 'systemId'
		| 'autoactivate'
		| 'sso'
	> {
	syncCode?: string;
}

export type CreateMultipleGroupsRequest = MutationRequest<AddGroupInput[]>;
export interface CreateHierarchyInputItem {
	id: string;
	name: string;
	parentId: string;
	active: string;
	syncCode?: string;
	isProperty: boolean;
	level: number;
	rowNumber: number;
}

interface CreateHierarchyInput {
	id: number;
	hierarchy: CreateHierarchyInputItem[];
}

export type CreateHierarchyGroupsRequest = MutationRequest<CreateHierarchyInput>;

export type CreateHierarchyGroupsResponse = {
	createHierarchy: string;
};
export interface GetGroupNamesTemplateResponse {
	getGroupNamesTemplate: DownloadUrl;
}

export interface GetHierarchySizeResponse {
	getHierarchySize: {
		totalCount: number;
	};
}
export interface GetHierarchySizeVariables {
	input: {
		groupId: string;
	};
}

export type ExportOwnershipGroupsRequest = GetOwnershipGroupPropertiesRequest;

export interface ExportOwnershipGroupsResponse {
	exportOwnershipGroups: DownloadUrl;
}

export type ExportGroupsRequest = GetGroupsListRequest;
export interface ExportGroupsResponse {
	exportGroups: DownloadUrl;
}

export type ExportDeletedGroupsRequest = GetDeletedGroupsRequest;
export interface ExportDeletedGroupsResponse {
	exportDeletedGroups: DownloadUrl;
}

export interface GetGroupsAncestorIdsRequest {
	input: {
		ids: number[];
	};
}

export type GetGroupsAncestorIdsResponse = {
	getRootAncestorIds: {
		ids: number[];
	};
};

export type SearchGroupsRequest = PaginatedRequest & {
	filters: {
		name?: string;
		withDeleted?: boolean;
	};
};

export type SearchGroupsResponse = {
	searchGroups: {
		groups: SearchGroup[];
		totalCount: number;
	};
};

export interface SearchGroup {
	id: number;
	parentId: number;
	name: string;
	path: string;
}

export interface GroupHasPropertyRequest {
	id: number;
}

export interface GroupHasPropertyResponse {
	groupHasProperty: boolean;
	filter?: {
		withInactive: boolean;
	};
}

export interface GetGroupsRequest {
	input: {
		ids: number[];
	};
}

export interface GetGroupsResponse {
	getGroups: Group[];
}
