import { Notification } from 'Components';
import { Form, Formik, FormikHelpers } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useHistory, generatePath } from 'react-router';

import { CREATE_GROUP, CREATE_TENANT } from 'Services/Api/Groups/Mutations';
import { Group } from 'Services/Api/Groups/Types';
import { groupSchema, tenantSchema } from '../schema';
import AddGroupFormContent from './AddGroupFormContent';
import { isBoolean, isNumber } from 'lodash';
import { getInitialValues } from './AddGroup.helpers';
import { APIErrorCodes } from 'app-types';
import { getAppRoutes } from 'Pages/App/App.constants';
import { checkApolloError } from 'Helpers/graphql';
import { useConfigs } from 'Hooks';
import { AddGroupValues } from '../Group.types';
interface AddGroupFormProps {
	group: Group | undefined;
}
const AddGroupForm = (props: AddGroupFormProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const configs = useConfigs();
	const isTenant = !props.group;

	const QUERY = isTenant ? CREATE_TENANT : CREATE_GROUP;
	const [createGroup] = useMutation(QUERY, {
		ignoreResults: false
	});

	const initialValues = getInitialValues(props.group);

	const validationSchema = useMemo(() => {
		const getValidationSchema = isTenant ? tenantSchema : groupSchema;
		return getValidationSchema(configs.maxGroupNameCharacters);
	}, [isTenant, configs.maxGroupNameCharacters]);

	const onSubmit = async (
		values: AddGroupValues,
		helpers: FormikHelpers<AddGroupValues>
	) => {
		try {
			const multiplePoolsValues = isTenant
				? {
						systemId: values.systemId
				  }
				: {};

			const input: AddGroupValues = {
				name: values.name,
				active: values.active,
				syncCode: values.syncCode || undefined,
				isProperty: values.isProperty,
				moduleEnabled: values.moduleEnabled,
				tenantId: isTenant ? values.tenantId : undefined,
				isArchived: values.isArchived,
				autoactivate: isTenant ? values.autoactivate : false,
				sso: isTenant ? values.autoactivate ?  values.sso : false : false,
				...multiplePoolsValues
			} as AddGroupValues;

			if (isBoolean(values.viewAllCourses)) {
				input.viewAllCourses = values.viewAllCourses;
			}
			if (values.parent) {
				input.parentId = values.parent.id;
			}
			await createGroup({
				variables: {
					input
				}
			});

			const returnUrl = generatePath(getAppRoutes().GROUPS, {
				groupId: isNumber(input.parentId) ? input.parentId : undefined
			});

			history.push(returnUrl);

			Notification.success({
				description: isTenant
					? t('group.addGroup.tenant.notificationSuccess')
					: t('group.addGroup.group.notificationSuccess')
			});
		} catch (error) {
			helpers.setSubmitting(false);

			const apolloError = checkApolloError(error);

			if (
				apolloError.is(APIErrorCodes.GroupHierarchyDepthLImitExceeded)
			) {
				Notification.error({
					description: configs.message
				});
				return;
			}

			if (
				apolloError.is(APIErrorCodes.GroupSyncCodeShouldBeUniqPerTenant)
			) {
				helpers.setErrors({
					syncCode: t(
						'group.fieldErrorCodes.GROUP_SYNC_CODE_SHOULD_BE_UNIQUE_PER_TENANT',
						{ path: 'Group sync code' }
					)
				});
				return;
			}

			if (apolloError.is(APIErrorCodes.GroupCannotBeProperty)) {
				Notification.error({
					description: t(
						'group.addGroup.errorCodes.GROUP_CAN_NOT_BE_PROPERTY'
					)
				});
				return;
			}

			const fieldErrors = apolloError.getFieldErrors(
				t,
				validationSchema.describe().fields,
				'group.fieldErrorCodes'
			);

			helpers.setErrors(fieldErrors || {});

			if (fieldErrors) return;

			const errors = apolloError.getNonFieldErrors(
				t,
				'groups.groupDetails.components.moveGroupModal.errorCodes',
				{
					[APIErrorCodes.GroupsHierarchySizeLimitExceeded]: {
						groupsHierarchySizeLimit: String(
							configs.groupsHierarchySizeLimit
						)
					}
				}
			);
			if (errors.length) {
				Notification.error({
					description: errors
				});
				return;
			}
			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
		>
			<Form>
				<AddGroupFormContent isTenant={isTenant} group={props.group} />
			</Form>
		</Formik>
	);
};

export default AddGroupForm;
