import { useTranslation } from 'react-i18next';
import { Alert, Checkbox } from 'Components';
import { useFormikContext } from 'formik';
import { AddGroupValues } from 'Pages/Group/Group.types';

function SsoField() {
	//const { t } = useTranslation();
	const { values, initialValues } = useFormikContext<AddGroupValues>();
	return (
		<>
			<Checkbox.FormikField name="sso">
				{'SSO'}
			</Checkbox.FormikField>
		</>
	);
}

export default SsoField;
